import "./Google.css";
import React from "react";
import { Row, Col } from "react-bootstrap";


const Google = () => {
  return (
    <div>
      <div className="container10">
        <Row>
          <Col
            md={{ span: 8, offset: 2 }}
            sm={{ span: 8, offset: 2 }}
            lg={{ span: 8, offset: 2 }}
            xl={{ span: 8, offset: 2 }}
            align="center"
          >

            <h1 align="center" style={{ color: "grey" }}>
              Supply<small style={{ color: "green", fontSize: 19 }}>24.se</small>
            </h1>
            <br></br>
            <h4 align="center" style={{ color: "white" }}>
              Your digital supply chains are important to us!
            </h4>
            <br></br>
            <p align="left" style={{ color: "grey", padding: 20 }}>
              {" "}
              supply24.se has more than 10 years of experience in international
              business and trade. We focus on international digital chains. Our
              intention is to increase, give access to and facilite industry
              comunication. 
            </p>
          </Col>
        </Row>
        <br></br> <br></br>
        <div className="container11">
          <Row>
            <Col
              md={{ span: 4, offset: 1 }}
              sm={{ span: 4, offset: 1 }}
              lg={{ span: 4, offset: 1 }}
              xl={{ span: 4, offset: 1 }}
              align="center"
            >
              <Col>
                <img
                  style={{
                    borderRadius: 10,
                  }}
                  src="/images/scm.png"
                  width="90%"
                  height="90%"
                  alt=""
                />
              </Col>
            </Col>

            <Col
              md={{ span: 4, offset: 2 }}
              sm={{ span: 4, offset: 2 }}
              lg={{ span: 4, offset: 2 }}
              xl={{ span: 4, offset: 2 }}
              align="center"
            >
              <br></br>
              <h4 style={{ color: "white" }}>
                Think digital and stay in business!
              </h4>
              <br></br>
              <p align="left" style={{ color: "grey", padding: 20 }}>
                Global IT activities increase for every year also in areas and
                countries with previous less IT activity. With IT involved, there
                will be greater oportunities for smaller operators to get their
                products airborne. 
              </p>
            </Col>
          </Row>
        </div>
        <div className="container13">
          <Row>
            <Col
              md={{ span: 4, offset: 1 }}
              sm={{ span: 4, offset: 1 }}
              lg={{ span: 4, offset: 1 }}
              xl={{ span: 4, offset: 1 }}
              align="center"
            >
              <br></br>
              <h4 style={{ color: "white" }}>
                Trade with digital industry focus
              </h4>
              <br></br>
              <p align="left" style={{ color: "grey", padding: 20 }}>
                Supply24.se intends to introduce digital tools for a variaty of 
                industries where the customer benefit results in improved business opportunities and 
                access to new markets. We wish that our digital work will let small and medium sized companies
                reach goals above expectations. 
                              </p>
            </Col>

            <Col
              md={{ span: 4, offset: 2 }}
              sm={{ span: 4, offset: 2 }}
              lg={{ span: 4, offset: 2 }}
              xl={{ span: 4, offset: 2 }}
              align="center"
            >
              <Col>
                <img
                  style={{
                    borderRadius: 10,
                  }}
                  src="/images/Globaltrade.jpg"
                  width="80%"
                  height="80%"
                  align="center"
                  alt=""
                />
              </Col>
            </Col>
          </Row>
        </div>
        <div className="container15">
          <img
            style={{
              borderRadius: 10,
            }}
            src="/images/logo1.png"
            width="20%"
            height="20%"
            align="center"
            alt=""
          />
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default Google;
